import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './auth/http-error-handler.service';
import { AppConifgVM } from '../app-shared/model/app-config';
import { ZzMenuTreesVM } from '../zz/zz-menu/shared/zz-menu';

@Injectable({ providedIn: 'root'})
export class AppConfigHttpService {
  private apiUrlMenu: string = environment.apiUrl + 'AppUserMenu';
  private apiUrlConfig: string = environment.apiUrl + 'AppConfig';
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('AppUserMenuHttpService');
  }
  getConfig(): Observable<AppConifgVM | null> {
    return this.http.get<AppConifgVM>(this.apiUrlConfig)
      .pipe(
        catchError(this.handleError('getHeroes', null))
      );
  }
  getUserMenus(): Observable<ZzMenuTreesVM | null> {
    return this.http.get<ZzMenuTreesVM>(this.apiUrlMenu)
      .pipe(
        catchError(this.handleError('getHeroes', null))
      );
  }
}

<app-menu-search #mnuSearch (menuOut)="onOkClick($event)"></app-menu-search>
<mat-toolbar class="app-toolbar">
  <span class="app-dialog-title">Menu chính</span>
  <span class="app-spacer"></span>
  <button mat-button color="warn" mat-dialog-close>Đóng
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<app-menu #appMenuCom (menuOut)="onOkClick($event)"></app-menu>


export interface AppMessage{
  isOK?: boolean;
  message?: string;
  longId?: number;
  errorType?: number;
}
export interface AppRequestPara{
  fromDate?: Date;
  toDate?: Date;
}
export interface AppReportRequestPara{
  id?: number;
  pdfId?: string;
  isCT?: boolean; //Giá theo chứng từ
}
export enum AppErrorTypeEN
{
    NotPermission = 0,
    UnAuthorized = 1,
    Orther = 9

}

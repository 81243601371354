import { Injectable } from "@angular/core";
import { ZzMenu } from "src/app/zz/zz-menu/shared/zz-menu";

@Injectable({ providedIn: 'root' })
export class AppMenuHelperService {
    getMenuNameById(mnuId: string) {
        return APP_MENUS.find(i => i.mnuId === mnuId);
    }
}

export const APP_MENUS: ZzMenu[] = [
    { mnuId: 'SD.SPQ', mnuName: 'Bảng giá chung theo sản lượng', parentName: 'Bán hàng', routeLink: '/sd-spq' },
    { mnuId: 'SD.SPT', mnuName: 'Bảng giá chào hàng', parentName: 'Bán hàng', routeLink: '/sd-spt' },
    { mnuId: 'SD.SPT2', mnuName: 'Bảng giá chào hàng 2', parentName: 'Bán hàng', routeLink: '/sd-spt2' },
    { mnuId: 'SD.SPP', mnuName: 'Bảng giá Hàng có sẵn', parentName: 'Bán hàng', routeLink: '/sd-spp' },
    { mnuId: 'SD.SPR', mnuName: 'Bảng giá bán hàng', parentName: 'Bán hàng', routeLink: '/sd-spr' },
    { mnuId: 'SD.SOR', mnuName: 'Đơn hàng bán', parentName: 'Bán hàng', routeLink: '/sd-sor' },
    { mnuId: 'SD.SOR-RPTL', mnuName: 'Báo cáo Chi tiết đơn hàng', parentName: 'Bán hàng', routeLink: '/sd-sor-line-rpt' },
    { mnuId: 'SD.DOR', mnuName: 'Phiếu giao hàng', parentName: 'Bán hàng', routeLink: '/sd-dor' },
    { mnuId: 'SD.DOR-RPTL', mnuName: 'Báo cáo Chi tiết giao hàng', parentName: 'Bán hàng', routeLink: '/sd-dor-line-rpt' },
    { mnuId: 'SD.SIN', mnuName: 'Hóa đơn bán hàng', parentName: 'Bán hàng', routeLink: '/sd-sin' },
    { mnuId: 'SD.SIN-RPTL', mnuName: 'Báo cáo Chi tiết hóa đơn bán hàng', parentName: 'Bán hàng', routeLink: '/sd-sin-line-rpt' },
    { mnuId: 'BA.REC-G', mnuName: 'Báo cáo Tổng hợp nợ phải thu', parentName: 'Quản lý công nợ', routeLink: '/ba-rec-rpt/gen' },
    { mnuId: 'BA.REC-L', mnuName: 'Báo cáo Chi tiết nợ phải thu', parentName: 'Quản lý công nợ', routeLink: '/ba-rec-rpt/line' },
    { mnuId: 'BA.PAY-G', mnuName: 'Báo cáo Tổng hợp nợ phải trả', parentName: 'Quản lý công nợ', routeLink: '/ba-pay-rpt/gen' },
    { mnuId: 'BA.PAY-L', mnuName: 'Báo cáo Chi tiết nợ phải trả', parentName: 'Quản lý công nợ', routeLink: '/ba-pay-rpt/line' },
    { mnuId: 'MM.PPR', mnuName: 'Bảng giá mua giấy tấm', parentName: 'Mua hàng', routeLink: '/mm-ppr/all' },
    { mnuId: 'MM.POR', mnuName: 'Đơn mua hàng (khác)', parentName: 'Mua hàng', routeLink: '/mm-por/pap' },
    { mnuId: 'MM.POR-PAP', mnuName: 'Đơn mua giấy tấm', parentName: 'Mua hàng', routeLink: '/mm-por' },
    { mnuId: 'MM.POR-RPTL', mnuName: 'Báo cáo Chi tiết mua hàng', parentName: 'Mua hàng', routeLink: '/mm-por-line-rpt' },
    { mnuId: 'MM.GRN', mnuName: 'Phiếu nhận hàng', parentName: 'Mua hàng', routeLink: '/mm-grn' },
    { mnuId: 'MM.GRE', mnuName: 'Phiếu nhập kho', parentName: 'Mua hàng', routeLink: '/mm-gre' },
    { mnuId: 'MM.GIS', mnuName: 'Phiếu xuất kho', parentName: 'Mua hàng', routeLink: '/mm-gis' },
    { mnuId: 'MM.PIN', mnuName: 'Hóa đơn mua hàng', parentName: 'Mua hàng', routeLink: '/mm-pin' },
    { mnuId: 'PP.MTO', mnuName: 'Kế hoạch Sản xuất (Giấy tấm mua ngoài)', parentName: 'Kế hoạch sản xuất', routeLink: '/pp-make-to-order' },
    { mnuId: 'PP.MOR', mnuName: 'Kế hoạch Sản xuất (Giấy nhà/Giấy cuộn)', parentName: 'Kế hoạch sản xuất', routeLink: '/pp-mor' },
    { mnuId: 'CA.CRE', mnuName: 'Phiếu thu/Giấy báo có', parentName: 'Ngân quỹ', routeLink: '/ca-cre' },
    { mnuId: 'CA.CPA', mnuName: 'Phiếu chi/Giấy báo nợ', parentName: 'Ngân quỹ', routeLink: '/ca-cpa' },
    { mnuId: 'LI.SUP', mnuName: 'Danh mục Nhà cung cấp', parentName: 'Danh mục', routeLink: '/li-sup' },
    { mnuId: 'LI.CUS', mnuName: 'Danh mục Khách hàng', parentName: 'Danh mục', routeLink: '/li-cus' },
    { mnuId: 'LI.PAD', mnuName: 'Danh mục Sub Khách hàng', parentName: 'Danh mục', routeLink: '/li-sub-cus' },
    { mnuId: 'LI.SPE', mnuName: 'Danh mục Nhân viên bán hàng', parentName: 'Danh mục', routeLink: '/li-spe' },
    { mnuId: 'LI.SAR', mnuName: 'Danh mục Khu vực giao hàng', parentName: 'Danh mục', routeLink: '/li-sar' },
    { mnuId: 'LI.SRO', mnuName: 'Danh mục Chành xe đi tỉnh', parentName: 'Danh mục', routeLink: '/li-sro' },
    { mnuId: 'LI.VEH', mnuName: 'Danh mục xe', parentName: 'Danh mục', routeLink: '/li-veh' },
    { mnuId: 'LI.DRI', mnuName: 'Danh mục tài xế', parentName: 'Danh mục', routeLink: '/li-dri' },
    { mnuId: 'LI.SPO', mnuName: 'Danh mục địa điểm giao hàng/Nhà máy', parentName: 'Danh mục', routeLink: '/li-spo' },
    { mnuId: 'LI.TAS', mnuName: 'Danh mục Công việc', parentName: 'Danh mục', routeLink: '/li-task' },
    { mnuId: 'LI.TGR', mnuName: 'Danh mục nhóm công việc', parentName: 'Danh mục', routeLink: '/li-task-group' },
    { mnuId: 'LI.TTE', mnuName: 'Danh mục Quy trình mẫu sản xuất (công việc)', parentName: 'Danh mục', routeLink: '/li-task-template' },
    { mnuId: 'LI.STO', mnuName: 'Danh mục kho', parentName: 'Danh mục', routeLink: '/li-store' },
    { mnuId: 'LI.PLG', mnuName: 'Danh mục Kết câu khách hàng', parentName: 'Danh mục', routeLink: '/li-plg' },
    { mnuId: 'LI.FLU', mnuName: 'Danh mục Sóng giấy', parentName: 'Danh mục', routeLink: '/li-flute' },
    { mnuId: 'LI.PLA', mnuName: 'Danh mục kết cấu Sản xuất', parentName: 'Danh mục', routeLink: '/li-pla' },
    { mnuId: 'LI.PBA', mnuName: 'Danh mục Kết cấu đơn (Kết cấu cơ bản)', parentName: 'Danh mục', routeLink: '/li-pba' },
    { mnuId: 'LI.PAC', mnuName: 'Danh mục Định hình thùng', parentName: 'Danh mục', routeLink: '/li-pac' },
    { mnuId: 'LI.PAF', mnuName: 'Danh mục Loại thùng (Công thức)', parentName: 'Danh mục', routeLink: '/li-paf' },
    { mnuId: 'LI.PGR', mnuName: 'Danh mục nhóm thùng', parentName: 'Danh mục', routeLink: '/li-pgr' },
    { mnuId: 'LI.PCO', mnuName: 'Danh mục Màu in', parentName: 'Danh mục', routeLink: '/li-pco' },
    { mnuId: 'LI.PSA', mnuName: 'Danh mục Mẫu in', parentName: 'Danh mục', routeLink: '/li-psa' },
    { mnuId: 'LI.PCA', mnuName: 'Danh mục Kiểu thùng/giấy', parentName: 'Danh mục', routeLink: '/li-pca' },
    { mnuId: 'LI.CAS', mnuName: 'Danh mục Két tiền/TK', parentName: 'Danh mục', routeLink: '/li-cas' },
    { mnuId: 'LI.MAC', mnuName: 'Danh mục máy cắt giấy', parentName: 'Danh mục', routeLink: '/li-machine' },
    { mnuId: 'LI.UOM', mnuName: 'Danh mục Đơn vị tính', parentName: 'Danh mục', routeLink: '/li-uom' },
    { mnuId: 'LI.TAX', mnuName: 'Danh mục Thuế suất', parentName: 'Danh mục', routeLink: '/li-tax' },
    { mnuId: 'LI.PRO-MOLD', mnuName: 'Danh mục khuôn', parentName: 'Danh mục', routeLink: '/li-pro-mold' },
    { mnuId: 'LI.DCM', mnuName: 'Danh mục Nhíp giấy (khuôn)', parentName: 'Danh mục', routeLink: '/li-dcm' },
    { mnuId: 'LI.PRO-FG', mnuName: 'Danh mục Thùng/Giấy', parentName: 'Danh mục', routeLink: '/li-pro-fg' },
    { mnuId: 'LI.PRO-PP', mnuName: 'Danh mục Giấy tấm', parentName: 'Danh mục', routeLink: '/li-pro-pp' },
    { mnuId: 'LI.PRO-PR', mnuName: 'Danh mục giấy cuộn', parentName: 'Danh mục', routeLink: '/li-pro-pr' },
    { mnuId: 'LI.PRO-SV', mnuName: 'Danh mục Mặt hàng dịch vụ', parentName: 'Danh mục', routeLink: '/li-pro-sv' },
    { mnuId: 'ZZ.USR', mnuName: 'Người sử dụng', parentName: 'Hệ thống', routeLink: '/zz-user' },
    { mnuId: 'ZZ.UGR', mnuName: 'Nhóm Người sử dụng', parentName: 'Hệ thống', routeLink: '/zz-group' },
    { mnuId: 'ZZ.APE', mnuName: 'Danh sách kỳ kế toán', parentName: 'Hệ thống', routeLink: '/zz-ape' },
    { mnuId: 'MM.APE', mnuName: 'Danh mục kỳ kế toán kho', parentName: 'Hệ thống', routeLink: '/mm-ape' },
    { mnuId: 'ZZ.DEP', mnuName: 'Bộ phận', parentName: 'Hệ thống', routeLink: '/zz-dep' },
    { mnuId: 'ZZ.ESM', mnuName: 'Cấu hình Email SMTP', parentName: 'Hệ thống', routeLink: '/zz-email-smtp' },
    { mnuId: 'ZZ.VPO', mnuName: 'Cấu hình Nhóm thuế VAT', parentName: 'Hệ thống', routeLink: '/zz-vpo' },
    { mnuId: 'ZZ.CONFIG', mnuName: 'Tham số hệ thống', parentName: 'Hệ thống', routeLink: '/zz-config' },
    { mnuId: 'ZZ.TABLE', mnuName: 'Cấu hình cột cho báo cáo', parentName: 'Hệ thống', routeLink: '/zz-table' },
    { mnuId: 'ZZ.IMAGE', mnuName: 'Cấu hình hình ảnh', parentName: 'Hệ thống', routeLink: '/zz-image' },
    { mnuId: 'ZZ.PDF', mnuName: 'Cấu hình Mẫu in (PDF)', parentName: 'Hệ thống', routeLink: '/zz-pdf' },
]
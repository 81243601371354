import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMessageService } from 'src/app/app-service/app-message.service';
import { AppMenuComponent } from '../menu/menu.component';
import { AppConfigHttpService } from 'src/app/app-service/app-config-http.service';
import { AppMenuSearchComponent } from '../menu-search/menu-search.component';
import { ZzMenu, ZzMenuTreesVM } from 'src/app/zz/zz-menu/shared/zz-menu';

@Component({
    selector: 'app-menu-dialog',
    templateUrl: './menu-dialog.component.html',
    styleUrls: ['./menu-dialog.component.css'],
    standalone: false
})
export class MenuDialogComponent {
  @ViewChild('mnuSearch', { static: true })
  searchComp$!: AppMenuSearchComponent;
  @ViewChild('appMenuCom', { static: true })
  menuComp$!: AppMenuComponent;
  constructor(
    public msgService: AppMessageService,
    public dialogRef: MatDialogRef<MenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: AppConfigHttpService
  ) {
    this.getMenus();
  }
  private getMenus() {
    this.msgService.startProcessing();
    this.httpService.getUserMenus().subscribe({
      next: (rs: ZzMenuTreesVM | null) => {
        if (rs && rs.msgItem?.isOK) {
          this.menuComp$.setItems(rs.mtrItems!)
          this.searchComp$.setItems(rs.mtrItems!)
          //Set Menu here
        }
        else {
          this.menuComp$.setItems([]);
          this.searchComp$.setItems([]);
        }
      },
      error: (error) => {
        this.menuComp$.setItems([]);
        this.searchComp$.setItems([]);
        this.msgService.add(error);
      },
      complete: () => {
        this.msgService.stopProcessing();
      },
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onOkClick(menu: ZzMenu): void{
    this.dialogRef.close(menu);
  }
}
